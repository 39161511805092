<template>
    <sections-visual
        class="section-visual-text"
        background-class="bg-ocean-700"
        inner-class="bg-ocean-200"
    >
        <template #background><slot name="background" /></template>
        <header class="">
            <h1 class=""><slot name="headline" /></h1>
            <p v-if="$slots.subheadline" class="text-md font-bold">
                <slot name="subheadline" />
            </p>
        </header>

        <div class="content">
            <slot name="content" />
        </div>
    </sections-visual>
</template>

<script>
export default {
    // props: {
    //     backgroundClass: {
    //         type: String,
    //         default: '',
    //     },
    //     innerClass: {
    //         type: String,
    //         default: '',
    //     },
    // },
}
</script>

<style lang="scss" scoped>
@import '@/assets/config';

:deep(.section-visual-inner) {
    > header {
        margin-bottom: 2rem;
        text-align: center;

        > h1 {
            margin-bottom: 0.5rem;
            word-break: break-word;
        }
    }
}

:deep(.content) {
    word-break: break-word;

    a {
        @include text-link;
    }

    p {
        margin-bottom: 1.5rem;
    }

    ol {
        counter-reset: item;
        list-style: decimal outside;
        margin-bottom: 2rem;

        ol {
            padding-left: 0.75rem;
        }

        > li {
            position: relative;
            display: block;
            margin-bottom: 1rem;

            &::before {
                position: absolute;
                display: block;
                right: 100%;
                white-space: nowrap;
                top: 0;
                margin-right: 0.5rem;
                content: counters(item, '.') ' ';
                counter-increment: item;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 1rem;
    }
}

@screen md {
    :deep(.section-visual-inner) {
        > header {
            margin-bottom: 3rem;

            > h1 {
                margin-bottom: 0.5rem;
            }
        }
    }
}

@screen lg {
    :deep(.section-visual-inner) {
        > header {
            margin-bottom: 4rem;

            > h1 {
                margin-bottom: 0.75rem;
            }
        }
    }
}
</style>