<template>
    <section class="section-visual">
        <div
            v-if="$slots.background"
            :class="['section-visual-background', backgroundClass]"
        >
            <slot name="background" />
        </div>
        <div :class="['section-visual-inner', innerClass]">
            <slot />
        </div>
    </section>
</template>

<script>
export default {
    props: {
        backgroundClass: {
            type: String,
            default: '',
        },

        innerClass: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss">
.section-visual {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    min-height: 100vh;
}

.section-visual-background {
    display: none;
}

.section-visual-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: auto 1 0;
    z-index: 10;
    padding: var(--section-padding-y) var(--section-padding-x);
}

@screen md {
    .section-visual {
        flex-flow: row nowrap;

        &.reverse {
            flex-direction: row-reverse;
        }
    }

    .section-visual-background {
        display: block;
        width: 40%;
        height: auto;
    }

    .section-visual-inner {
        width: 60%;
        flex: none;
    }
}
</style>